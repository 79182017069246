window.errorFunction = function (_errorType){
  const _self = [];
   _self.error_number = document.getElementsByClassName('error-number')[0];
  document.getElementsByClassName('error-message')[0].classList.add('open');
  _self.error = _errorType;
  _self.mediaNumer = 200;
  _self.fullTime = 400;
  _self.counter = 200;
  let counterFunction = setInterval(function () {

    if (_self.counter < 10) {
      document.getElementsByClassName('error-number')[0].innerHTML = "00" + _self.counter;
    } else {
      if ( _self.counter < 100) {
        document.getElementsByClassName('error-number')[0].innerHTML = "0" + _self.counter;
      } else {
        document.getElementsByClassName('error-number')[0].innerHTML = _self.counter;
      }
    }
    _self.counter++;
    if (_self.mediaNumer <=  _self.counter) {
      clearInterval(counterFunction);


      let counterFunction2 = setInterval(function () {
        console.log( _self.counter);
        document.getElementsByClassName('error-number')[0].innerHTML =  _self.counter;
        _self.counter++;
        if (_self.fullTime <=  _self.counter) {
          clearInterval(counterFunction2);

          let counterFunction3 = setInterval(function () {
            console.log( _self.counter);
            document.getElementsByClassName('error-number')[0].innerHTML =  _self.counter;
            _self.counter++;
            if (_self.error <  _self.counter) {
              clearInterval(counterFunction3);
            }
          }, 30)
        }
      }, 10)


    }
  }, 1);

};

